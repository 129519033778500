import dayjs from 'dayjs'

import { currentDevice } from '../../../../core/utils/currentDevice'
import Modal from '../../../../shared/modal/modal'
import { hasParam } from '../../../../vendors/urlParser/urlParser'

import { initializeUtagEvents } from './sta_modal_form.analytics'

let hotelsFilled = false
let dataInitialized = false

function loopHotels(container, fastbooking_data) {
  const hotelsSelect = container.querySelector('.js-hotels-select')
  fastbooking_data.modal_hotels[1].forEach(hotel => {
    const option = document.createElement('option')
    option.text = hotel.title
    option.value = hotel.hotel_code

    hotelsSelect.appendChild(option)
  })
  hotelsFilled = true
}
/*eslint-disable*/
function fillHotelsSelect(container) {
  if (!hotelsFilled) {
    if (IB.hotels_data.check()) {
      loopHotels(container, IB.hotels_data.getData())
    } else {
      IB.hotels_data.get(function () {
        loopHotels(container, IB.hotels_data.getData())
      })
    }
  }
}

function loadDatePicker() {
  const $checkin_date = $('.js-checkin-datepicker, .js-checkout-datepicker')

  if ($checkin_date.length > 0) {
    const $el = $('#claim-points-form-dates')
    const language = document.documentElement.getAttribute('data-js-lang')
    const date_format = $('html').attr('data-date-format')
    const start_of_week = $('html').attr('data-start-of-week')

    const $staFormContainer = $('.js-step-two-panel')
    const $check_in = $staFormContainer.find('.js-checkin-datepicker')
    const $check_out = $staFormContainer.find('.js-checkout-datepicker')

    const date = new Date()
    if ($el.data('dateRangePicker')) {
      $el.data('dateRangePicker').destroy()
    }
    $el
      .dateRangePicker({
        autoClose: true,
        format: date_format,
        startOfWeek: start_of_week,
        language,
        startDate: new dayjs().subtract(8, 'month').format(date_format),
        endDate: new dayjs().add(8, 'month').format(date_format),
        hoveringTooltip: false,
        showTopbar: false,
        stickyMonths: true,
        positionRelativeToDocument: true,
        setValue: function (s, s1, s2) {
          $check_in.val(s1);
          $check_out.val(s2);
        },
      })
      .on('datepicker-open', function () {
        // Lock Scroll on datepicker open only in mobile
        currentDevice.isMobile && ScrollLock.on()
        $el.data('dateRangePicker').calcPosition()
      })
      .on('datepicker-opened', function (ev, picker) {
        $(this).prop('readonly', true)
      })
      .on('datepicker-first-date-selected', function (ev, picker) {
        $(this).prop('readonly', false)
        $(this).val(dayjs(picker.time).format(date_format)).trigger('change')
        $el.closest('form').parsley().validate('datepicker')
      })
      .on('datepicker-close', function () {
        // Unlock Scroll on datepicker close only in mobile
        currentDevice.isMobile && ScrollLock.off()
      })

    $el.on('focus', function () {
      $el.data('dateRangePicker').open()
    })

    // PY04776-533 - 761157 - Calendario de registro se descoloca
    // Fix bug that if the postregister modal is closed and the data picker is open, the data picker closes as well.
    $('[data-fancybox-close]').on('click', function () {
      $el.data('dateRangePicker').close()
    })
  }
}

export default function init() {
  const modalButton = Array.from(document.querySelectorAll('.js-sta-modal-form'))
  const modalContainer = document.querySelector('.sta-modal-form')

  if (!modalContainer) return

  const nextButton = modalContainer.querySelector('.js-next-button-modal')
  const prevButton = modalContainer.querySelector('.js-prev-button-modal')
  const stepOnePanel = modalContainer.querySelector('.js-step-one-panel')
  const stepTwoPanel = modalContainer.querySelector('.js-step-two-panel')
  const uploadFileInput = modalContainer.querySelector('.js-btn-upload')
  const errorFile = modalContainer.querySelector('.error-message')

  if (modalButton && modalButton.length > 0) {
    modalButton.forEach(staModal => {
      staModal.addEventListener('click', e => {
        e.preventDefault()

        Modal.show('points-modal-nav', {
          onShow: () => {
            if (!ScrollLock.isLock()) {
              window.scrollTo(0,0)
              if (!dataInitialized) {
                initializeUtagEvents()
                dataInitialized = true
              }
              loadDatePicker()
              fillHotelsSelect(modalContainer)
            }
            ScrollLock.on()
          },
          onClose: () => {
            ScrollLock.off()
            closeCalendar()
          },
          awaitCloseAnimation: true,
        })
      })
    })

    function closeCalendar() {
      const $el = $('#claim-points-form-dates')
      $el.data('dateRangePicker').close()
    }

    nextButton.addEventListener('click', () => {
      stepOnePanel.classList.remove('active')
      stepTwoPanel.classList.add('active')
      modalContainer.scrollTop = 0;
    })

    prevButton.addEventListener('click', e => {
      e.preventDefault()
      stepTwoPanel.classList.remove('active')
      stepOnePanel.classList.add('active')
      modalContainer.scrollTop = 0;
    })

    // Max size for add files input
    const maxSize = 2097152
    uploadFileInput.addEventListener('change', e => {
      const thisElement = e.target
      if (thisElement.files.length <= 0) return
      const file = thisElement.files[0]

      if (file.size > maxSize || file.type !== 'application/pdf') {
        uploadFileInput.value = ''
        uploadFileInput.parentElement.style.border = '1px solid red'
        errorFile.classList.remove('hidden')
      } else {
        uploadFileInput.parentElement.style.border = '1px solid #F1F2F9'
        errorFile.classList.add('hidden')
      }
    })
  }
}

if (hasParam('claim_points_code')) {
  Modal.show('claim-points-form-sent', {
    onShow: () => {
      ScrollLock.on()
    },
    onClose: () => {
      ScrollLock.off()
    },
    awaitCloseAnimation: true,
  })
}

init()
