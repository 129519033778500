import { init as initHotelGallery } from '../../blocks/hotel_gallery/hotel_gallery'
import { openLoader } from '../../elements/wait_loading/wait_loading.js'
import { openGalleryPopup } from '../../shared/gallery_popup/gallery_popup'

let galleryInitialized = false

$(document).one('click', '[data-multi-modal-trigger]', function (e) {
  e.preventDefault()
  const $el = $(this)
  initIbGallery()
  openLoader()

  import(
    /* webpackChunkName: 'multi_modal' */
    `../multi_modal/multi_modal.js`
  )
    .then(blockJS => blockJS.openMultiModal($el))
    .catch(() => {})
})

$(document).one('click', '.is-star-prestige', function (e) {
  e.preventDefault()
  const $el = $(this)
  initIbGallery()
  import(
    /* webpackChunkName: 'star_prestige' */
    `../../shared/star_prestige/star_prestige`
  )
    .then(blockJS => blockJS.openStarPrestige($el))
    .catch(() => {})
})

// If isHotel -> render hotel_gallery
if (IB.currentPageType.isHotel()) {
  initHotelGallery()
} else {
  $(document).one('click', '[data-fancybox-gallery]', function (e) {
    e.preventDefault()
    const $el = $(this)
    const imageId = getThumbnailId($el)
    if (!galleryInitialized) {
      galleryInitialized = true

      import(
        /* webpackChunkName: 'ib_gallery' */
        `./ib_gallery`
      )
        .then(blockJS => blockJS.initGalleryPopup($el, imageId))
        .catch(() => {})
    } else {
      openGalleryPopup($el, imageId)
    }
  })
}

const getThumbnailId = function ($el) {
  return $el.attr('data-id') ? parseInt($el.attr('data-id')) : null
}

const initIbGallery = function () {
  if (!galleryInitialized) {
    galleryInitialized = true
    import(
      /* webpackChunkName: 'ib_gallery' */
      `./ib_gallery`
    )
  }
}
