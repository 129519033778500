
export function openGalleryPopup($element, imageId) {
  let gallery =  document.querySelector('.js-gallery-items');

  $.fancybox.open($element, {
    padding:        0,
    margin:         0,
    height:         '100%',
    width:          '100%',
    fitToView:      true,
    autoSize:       false,
    scrolling:      'hidden',
    autoScale:      false,
    autoDimensions: false,
    touch:          false,
    wrapCSS:        'fancybox-gallery-popup',
    helpers:        {
      overlay: {
        locked: true
      }
    },
    beforeShow: function (instance, current) {
      $element.removeClass('hidden');
      $(current.src).addClass('from-gallery-popup');
      let filterTagId = current.opts.$orig.data('gallery-tag-id');
      let $gallery = $(current.src).find('.js-ib-gallery');
      let galleryAlreadyOpened = $gallery.data('galleryAlreadyOpened');

      if (!galleryAlreadyOpened) {
        $gallery.ibGallery({
          initFilterTag: filterTagId
        });
        $gallery.data('galleryAlreadyOpened', true);
      } else if (filterTagId) {
        $gallery.ibGallery('filter.applyOnly', filterTagId);
      } else {
        $gallery.ibGallery('filter.showAll', filterTagId, imageId);
      }
      ScrollLock.on();
      if (imageId){
        gallery.style.opacity = '0';
      }
    },
    afterShow: function (instance, current) {
      let $gallery = $(current.src).find('.js-ib-gallery');


      if (imageId){
        const nodeListImagesWithImageId = document.querySelectorAll('.gallery-item[data-id="image-' + imageId + '"]'); 
        $gallery.ibGallery('navigate.goTo', findSwiperSlideIndexImage(nodeListImagesWithImageId));
        setTimeout(function(){
          gallery.style.opacity = '1';
        }, 300);
      }
    },
    afterClose: function () {
      $element.removeClass('hidden');
      ScrollLock.off();

      // Escuchamos nuevos click para abrir la galería
      $(document).one('click', '[data-fancybox-gallery]', function (e) {
        e.preventDefault();
        let $el = $(this);
        let imageId = $el.attr('data-id') ? parseInt($el.attr('data-id')) : null;
        openGalleryPopup($el, imageId);
      });


      let onCloseGallery = new Event('onCloseGallery');
      document.dispatchEvent(onCloseGallery);



    }
  });
}

function findSwiperSlideIndexImage(nodeListImagesWithImageId){
  if (nodeListImagesWithImageId){
    const imageWithSwiperSlideIndex = Array.from(nodeListImagesWithImageId).find(image =>{
      return image && image.dataset && image.dataset.swiperSlideIndex
    });

    if (imageWithSwiperSlideIndex){
      return parseInt(imageWithSwiperSlideIndex.dataset.swiperSlideIndex);
    }
  }
  return 0;
}
