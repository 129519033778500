import { readCookie, setCookie } from '../../utils/cookie_manager';
import _find from 'lodash/find';

// function from jQuery number plug-in 2.1.5
$.number = function (number, decimals, dec_point, thousands_sep) {
  // Set the default values here, instead so we can use them in the replace below.
  thousands_sep = (typeof thousands_sep === 'undefined') ? (new Number(1000).toLocaleString() !== '1000' ? new Number(1000).toLocaleString().charAt(1) : '') : thousands_sep;
  dec_point = (typeof dec_point === 'undefined') ? new Number(0.1).toLocaleString().charAt(1) : dec_point;
  decimals = !isFinite(+decimals) ? 0 : Math.abs(decimals);

  // Work out the unicode representation for the decimal place and thousand sep.
  let u_dec = ('\\u' + ('0000' + (dec_point.charCodeAt(0).toString(16))).slice(-4));
  let u_sep = ('\\u' + ('0000' + (thousands_sep.charCodeAt(0).toString(16))).slice(-4));

  // Fix the number, so that it's an actual number.
  number = (number + '')
    .replace('\.', dec_point) // because the number if passed in as a float (having . as decimal point per definition) we need to replace this with the passed in decimal point character
    .replace(new RegExp(u_sep, 'g'), '')
    .replace(new RegExp(u_dec, 'g'), '.')
    .replace(new RegExp('[^0-9+\-Ee.]', 'g'), '');

  let n = !isFinite(+number) ? 0 : +number;
  let s = '';
  let toFixedFix = function (n, decimals) {
    return '' + (+(Math.round(('' + n).indexOf('e') > 0 ? n : n + 'e+' + decimals) + 'e-' + decimals));
  };

  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (decimals ? toFixedFix(n, decimals) : ('' + n).split('.')[0]).split('.'); // Do not round if there are no decimals. Math.round(n) ---> n).split('.')[0]
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousands_sep);
  }
  if ((s[1] || '').length < decimals) {
    s[1] = s[1] || '';
    s[1] += new Array(decimals - s[1].length + 1).join('0');
  }
  return s.join(dec_point);
};


function init() {
  let currency = readCookie('currency') || $("body").data("default-currency");
  let base_domain = document.domain.split('.').slice(-2).join('.');
  let shared_domain = '.' + base_domain;

  //Remove cookie in domain
  setCookie('currency', '-1', '-1', null);

  //If cookie doesnt exists load default cookie for url user market.
  if(document.querySelector('.currency-selector option[value="'+currency+'"]') == null){
    currency = $("body").data("default-currency");
    setCookie('currency', currency, 365, null, shared_domain);
  }

  if (currency) {
    updatePrices(currency);
  }

  if(typeof readCookie('currency') === "undefined"){
    setCookie('currency', currency, 365, null, shared_domain);
  }
  updateCurrencySelectors(currency);

  // on change we update all prices of page
  $(document).on('change', '.currency-form select', function () {
    currency = $(this).val();

    if (currency) {
      try{
        utag_data.page_currency = currency;
      }catch(error){
        console.log(error)
      }
    }
    $.fancybox.open({
      src:         '#msg-currency-change',
      autoSize:    false,
      width:       476,
      height:      'auto',
      padding:     0,
      wrapCSS:     'fancybox-currency-change',
      backFocus:   false,
      beforeClose: function () {
        if(!$('html').hasClass('updated-currency')) {
          currency = readCookie('currency');
        }
        setCookie('currency', currency, 365, null, shared_domain);
        updatePrices(currency);
        updateCurrencySelectors(currency);
        $('html').removeClass('updated-currency');
      }
    });
  });

  $(document).on('price_displayed', function () {
    updatePrices(currency);
  });

  $(document).on('click', '#msg-currency-change .update-currency', function () {
    updatePrices(readCookie('currency') || $("body").data("default-currency"));
    updateCurrencySelectors(currency);
    // Se añade un clase temporal para saber que la moneda fue cambiada
    $('html').addClass('updated-currency');

    $.fancybox.close();
  });

  $(document).on('click', '#msg-currency-change .no-update-currency', function () {
    $.fancybox.close();
  });

  $(document).on('click', '.hotel-list-link', function (event) {
    event.preventDefault();

    if (IB.currentDevice !== 'mobile') {
      $('#hotels-filter-map').hide();
      $('[data-block-comparator]').addClass('hidden current');
      $(this).addClass('active');
      $('.hotel-map-link').removeClass('active disabled');
      $('.js-hotel-comparison-link').removeClass('active disabled');
      $('.current[data-block-list]').removeClass('hidden current');
    }
  }).on('click', '.destination-index-header .hotel-map-link', function (event) {
    event.preventDefault();
    if ($(this).hasClass('disabled')) return false;

    let $hotels_filter_map = $('#hotels-filter-map');

    if (IB.currentDevice !== 'mobile') {
      $hotels_filter_map.show();
      $('.hotel-list-link').removeClass('active disabled');
      $('.js-hotel-comparison-link').removeClass('active disabled');
      $(this).addClass('active');
      $('[data-block-list]').not('.hidden').addClass('current hidden');
      $(this).addClass('disabled');
    }

    if (!$(this).hasClass('map-is-activated')) {
      IB.hotels_filter_map.init($(this), {
        type: 'visible'
      });
    } else {
      IB.hotels_filter_map.filterVisible();
    }
  })
    .on('click', '.destination-index-header .actions a.js-hotel-comparison-link', function (e) {
      e.preventDefault();
      let currency = readCookie('currency') || $("body").data("default-currency");
      if (currency) {updatePrices(currency);}
      $('#footer-sticky').removeClass('collapsed');
      $('#footer-sticky .action a').attr('aria-expanded', true);
      $('body').addClass('has-sticky-footer');
      $('#footer-sticky').attr('tabindex', '-1').focus();

      if (IB.currentDevice !== 'mobile') {
        $('#hotels-filter-map').hide();
        $('[data-block-list]').addClass('hidden current');
        $(this).addClass('active');
        $('.hotel-list-link').removeClass('active disabled');
        $('.hotel-map-link').removeClass('active disabled');
        $('.current[data-block-comparator]').removeClass('hidden current');
      }
      $('.hotels-comparator-mobile').hide();
    })
    .on('click', '.destination-index-header .actions a:not(.js-hotel-comparison-link)', function (e) {
      $('body').removeClass('has-sticky-footer');
      $('#footer-sticky .action a').attr('aria-expanded', false);
    });
}

// change aria-selected attr of tabs when change selected
$(document).on('click', '.tabs-menu a', function () {
  $('.tabs-menu a').not(this).attr('aria-selected', false);
  $(this).attr('aria-selected', true);
});

function forceUpdate($block) {
  let currency = readCookie('currency') || $("body").data("default-currency");
  // if (currency && currency != "DEF") {
  updatePrices(currency, $block);
  // }
}

$(document).on('updatePrices', function (event) {
  let currency = readCookie('currency') || $("body").data("default-currency");
  updatePrices(currency);
});

function updatePrices(currency, $block) {
  let user_resident_type = readCookie('resident');
  let $prices_to_convert;
  if ($block !== undefined && $block !== null && $block.length) {
    $prices_to_convert = $block.find('[data-curr-converter]');
  } else {
    $prices_to_convert = $('[data-curr-converter]');
  }

  $.each($prices_to_convert, function (index, element) {
    let $this = $(this);
    let converter_type = $this.data('curr-converter');
    let price_amount = $this.data('price');
    let price_currency = $this.data('currency');

    if (price_currency === undefined) return true; // return true equals continue in $.each. Won't break the loop. Continue to next iteration.

    if (typeof user_resident_type !== 'undefined') {
      let residentPriceData = $this.data('resident-price');
      let resident_type = $this.data('resident-type');
      if (typeof residentPriceData !== 'undefined' && residentPriceData > 0 &&
          user_resident_type === resident_type) {
        price_amount = residentPriceData;
      }
    }

    if (price_currency != '%') {
      if (price_currency == 'MXN$') {
        price_currency = 'MXN';
        price_amount = price_amount.replace(',', '');
      }
      let conversions = window.IB['conversions_from_' + price_currency.toLowerCase()];
      let to_currency = _find(conversions, function (c) {return c.to === currency;});
      if (typeof to_currency !== 'undefined') {
        let result_amount = price_amount * to_currency.factor;
        to_currency = _find(window.IB.currencies_data, function (c) {return c.code === (currency == 'DEF' ? price_currency : currency);});

        let price_with_decimals = false; // Deactivated. Read from config maybe?
        let result_formatted = $.number(result_amount, (price_with_decimals ? to_currency.num_dec : 0), to_currency.fr_del, to_currency.th_del);
        const priceClasses = $(element).find('.price').attr('class');
        const coinClasses = $(element).find('.coin').attr('class');
        let html_price = `<span class="${priceClasses}">` + result_formatted + '</span>';
        let html_cur_code = `<span class="${coinClasses}">` + to_currency.code + '</span>';
        let html_cur_symbol = `<span class="${coinClasses}">` + to_currency.symbol + '</span>';
        let html_tag = to_currency.format.replace('{code}', html_cur_code).replace('{symbol}', html_cur_symbol).replace('{amount}', html_price);
        if (converter_type == 'inline') {
          $(element).find('.coin').remove();
          $(element).find('.price').replaceWith(html_tag);
        } else {
          $(element).html(html_tag);
        }
      }
    }
  });
  $(document).trigger('currency_updated');

  // Event created for non-JQuery users
  const event = new Event('currency_updated');
  document.dispatchEvent(event);
}

function buildTagsFromPrice(price) {
  let price_amount = 0;
  let html_tag = '';
  let currency = readCookie('currency') || $("body").data("default-currency");

  if (currency && (currency != '%')) {
    if (currency == 'MXN$') {
      currency = 'MXN';
      price_amount = price_amount.replace(',', '');
    }
    price_amount = +price_amount;
    let conversions = window.IB['conversions_from_' + currency.toLowerCase()];
    let to_currency = _find(conversions, function (c) {return c.to === currency;});
    if (typeof to_currency !== 'undefined') {
      let result_amount = price * to_currency.factor;
      to_currency = _find(window.IB.currencies_data, function (c) {return c.code === currency;});

      let price_with_decimals = false; // Deactivated. Read from config maybe?
      let result_formatted = $.number(result_amount, (price_with_decimals ? to_currency.num_dec : 0), to_currency.fr_del, to_currency.th_del);
      let html_price = '<span class="price t-price">' + result_formatted + '</span>';
      let html_cur_code = '<span class="coin t-coin">' + to_currency.code + '</span>';
      let html_cur_symbol = '<span class="coin t-coin">' + to_currency.symbol + '</span>';
      html_tag = to_currency.format.replace('{code}', html_cur_code).replace('{symbol}', html_cur_symbol).replace('{amount}', html_price);
    }
  }

  return html_tag;
}

function updateCurrencySelectors(currency) {
  let currency_form_option = document.querySelector('.currency-form option[value="' + currency + '"]');
  let currency_text = currency_form_option !== null ? currency_form_option.value : null;
  if(currency_text === null)
    return

  if(document.querySelector('.currency-form span.fk-select-label') !== null)
    document.querySelector('.currency-form span.fk-select-label').textContent = currency_text;
  let $select = $('.currency-form select');
  let present = false;

  $select.find('option').each(function () {
    present = $(this).val() === currency ? true : present;
  });

  currency = !present ? 'DEF' : currency;

  $select.val(currency);
  let currency_icon = $('.currency-form span.fk-select');
  currency_icon.removeClass();
  currency_icon.addClass('fk-select currency-'+currency);
  $('.currency-form span.fk-select-label').text(currency_text);

  // Update mobile
  let currency_selector_text = $('.mobile-menu').find('.currency-selector').find('.link').text();
  let selector_text_currency_value = currency_selector_text.split(":");

  $('.mobile-menu').find('.currency-selector').find('.link').text(selector_text_currency_value[0] + ": " + currency);

}

window.IB.currencyForm = {
  init:           init,
  forceUpdate:    forceUpdate,
  updateSelector: updateCurrencySelectors,
  buildTagsFromPrice: buildTagsFromPrice
};


IB.currencyForm.init();


$(document).ready(function () {
  // Show comparator tab if url param 'showComparator' is present and there's a comparator in page
  let $comparatorLink = $('.js-hotel-comparison-link');
  if (IB.urlParser.getParam('showComparator') && $comparatorLink.length && $('[data-block-comparator]').length && !$('[data-block-comparator]:visible').length) {
    $comparatorLink.click();
    $(window).load(function () {
      $('html, body').animate({
        scrollTop: $comparatorLink.offset().top - 50
      }, 500);
    });
  }
});
