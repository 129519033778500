import { currentDevice } from '../../core/utils/currentDevice'
import MainHeader from '../../layout/header/reb/header'
import Modal from '../../shared/modal/modal'
import inViewport from '../../utils/inViewport'
import getOffset from '../../utils/offset'
import { scrollToElement } from '../../utils/scroll_to_element'
import { initTestRebHeader } from '../../utils/test_reb_header'
import { FB_STICKY_CLOSE_EVENT, FB_STICKY_OPEN_EVENT } from '../fastbooking/fastbooking.constants'

import { initializeUtagEvents } from './header_sticky_hotel.analytics'

const HEADER_STICKY_SELECTOR = '.js-header-sticky-hotel'

const headerStickyHotel = document.querySelector(HEADER_STICKY_SELECTOR)
const header = document.querySelector(
  `.js-hotel-header, .js-hotel-reb-header, ${HEADER_STICKY_SELECTOR}, .hotel-header-inner`
)

// PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
const mainHeader = document.querySelector('.js-reb-header')

const fastbooking = currentDevice.isMobile
  ? document.querySelector('#fastbooking-mobile')
  : document.querySelector('.fastbooking.home')

const is_hotel_page = document.querySelector('body').classList.contains('hotel-one-landing-page')

function showHeaderSticky() {
  // If sticky-force class is set in fastbooking, avoid opening the header
  // because we are opening the fastbooking from a fancybox
  if (fastbooking.classList.contains('sticky-force')) {
    fastbooking.classList.remove('sticky-force')
  } else if (initTestRebHeader) {
    // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
    if (!mainHeader.classList.contains('sticky')) {
      mainHeader.classList.add('sticky')
    }
    // END - PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
  } else if (!headerStickyHotel.classList.contains('sticky')) {
    headerStickyHotel.classList.add('sticky')
  }
}

function hideHeaderSticky() {
  if (initTestRebHeader) {
    // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
    mainHeader.classList.remove('sticky')
    // END - PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
  } else {
    headerStickyHotel.classList.remove('sticky')
  }
}

// Función para verificar si el ancla existe en la página
function isAnchorValid(anchorId) {
  const anchor = document.getElementById(anchorId)
  return !!anchor
}

function cloneStickyMenu() {
  const hotelInfoWrapper = header.querySelector('.hotel-info-wrapper')?.cloneNode(true)
  if (hotelInfoWrapper) {
    headerStickyHotel.querySelector('.inner-header .wrapper').append(hotelInfoWrapper)
  }

  const internalNav = document.querySelector('.js-hotel-internal-nav')?.cloneNode(true)
  if (internalNav === null || internalNav === undefined) return
  // Añadimos clase al internal nav para que al clonar el elemento, no se lie al calcular la altura para hacerlo sticky.
  document.querySelectorAll('.js-hotel-internal-nav').forEach(nav => nav.classList.add('no-sticky'))

  // !! A estas líneas no les he encontrado explicación
  internalNav.querySelector('[data-show=true]')?.setAttribute('href', '#more-container-sticky')
  internalNav.querySelector('#more-container')?.setAttribute('id', 'more-container-sticky')

  // Hotel page only code
  if (is_hotel_page) {
    // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
    if (initTestRebHeader) {
      MainHeader.copyInternalNav(internalNav)
      // END - PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
    } else {
      const clone_menu_nfh = document.createElement('div')
      clone_menu_nfh.classList.add('internal-nav-cnt-nfh')
      clone_menu_nfh.append(internalNav)
      headerStickyHotel.querySelector('.js-sticky-internal-nav .wrapper').append(clone_menu_nfh)
    }
  } else {
    headerStickyHotel.querySelector('.js-sticky-internal-nav .wrapper').append(internalNav)
  }
}

function handleStickyHeaderVisibility() {
  const windowScroll = window.scrollY
  const header_height = header.offsetHeight
  const breadcrumbPosition = getOffset(header).top + header_height
  if (windowScroll > breadcrumbPosition) {
    showHeaderSticky()
  } else {
    hideHeaderSticky()

    const containerSticky = document.querySelector('#more-container-sticky')
    if (containerSticky) {
      containerSticky.style.display = 'none'
    }

    document.querySelectorAll('.floating-action .active').forEach(elem => {
      elem.classList.remove('active')
    })
  }
}

function initInternalNav() {
  initializeUtagEvents()

  const internalNavs = document.querySelectorAll('.js-hotel-internal-nav')

  // Recorrer cada bloque y obtener los elementos <li> con data-check-anchor="true"
  internalNavs.forEach(internalNav => {
    const menuItems = internalNav.querySelectorAll('.js-menu-item-in-bar[data-check-anchor="true"]')
    // Recorrer y mostrar los elementos encontrados en cada bloque
    menuItems.forEach(menuItem => {
      const menuLink = menuItem?.querySelector('.js-menu-item-link')
      const anchorId = menuLink.getAttribute('href').substring(1)
      if (!isAnchorValid(anchorId)) {
        menuItem.remove()
      }
    })
  })

  // Recorrer cada bloque y añadir un click para ir a la sección correspondiente
  internalNavs.forEach(internalNav => {
    const menuItems = internalNav?.querySelectorAll('.js-menu-item-in-bar')

    menuItems.forEach(menuItem => {
      const menuLink = menuItem?.querySelector('.js-menu-item-link')

      if (!menuLink) return

      menuLink.addEventListener('click', e => {
        e.preventDefault()
        if (menuLink.classList.contains('active')) return

        let scrollOffset = 20 //minimum margin

        if (!currentDevice.isMobile) {
          scrollOffset += headerStickyHotel.clientHeight
          const headband = document.querySelector('.js-headband')

          if (headband) {
            scrollOffset += headband.clientHeight
          }
        } else {
          Modal.close()
        }

        const scrollDestinationId = menuLink.getAttribute('href')
        scrollToElement(scrollDestinationId, scrollOffset)
      })
    })
  })

  // Hacer que los elementos del internal nav se marquen como activos al hacer scroll
  const internal_nav = document.querySelector('.js-hotel-internal-nav')
  if (internal_nav) {
    const sectionsIds = [...internal_nav.querySelectorAll('.js-menu-item-link')]
      .filter(link => link.getAttribute('data-href'))
      .map(link => link.getAttribute('href'))
    const sections = document.querySelectorAll(sectionsIds.join(','))
    sections.forEach(section => {
      inViewport(
        section,
        element => {
          if (element.isIntersecting) {
            const navs = document.querySelectorAll('.js-hotel-internal-nav')

            navs.forEach(nav => {
              // Remove all active classes from menu items
              const activeMenuItems = nav.querySelectorAll(`.js-menu-item-in-bar.active`)
              activeMenuItems.forEach(item => item.classList.remove('active'))

              // Add active class to current menu item
              const currentMenuItems = nav.querySelectorAll(`.js-menu-item-link[href="#${section.id}"]`)
              currentMenuItems.forEach(item => item.closest('.js-menu-item-in-bar').classList.add('active'))

              const activeItem = nav.querySelector('.js-menu-item-in-bar.active')

              if (activeItem) {
                nav.scrollTo({
                  top: 0,
                  left: activeItem.offsetLeft,
                  behavior: 'smooth',
                })
              }
            })
          }
        },
        {
          threshold: 0.5,
        }
      )
    })
  }
}

function init() {
  if (header && !IB.is_in_mice) {
    // Este JS necesita ejecutarse antes que el fastbooking.js ya que evita que tengamos dos fastbookings a la vez
    // y se lie a la hora de ejecutar diferentes acciones por tener un selector con dos objetos fastbooking
    if (document.querySelectorAll('.fastbooking.home').length > 1) {
      document.querySelector('#floating-fastbooking .fastbooking')?.remove()
    }

    cloneStickyMenu()

    initInternalNav()

    window.addEventListener('scroll', function () {
      handleStickyHeaderVisibility()
    })

    // Show header sticky when sticky fastbooking was shown
    fastbooking.addEventListener(FB_STICKY_CLOSE_EVENT, handleStickyHeaderVisibility)

    // Close header sticky when sticky fastbooking was shown
    fastbooking.addEventListener(FB_STICKY_OPEN_EVENT, hideHeaderSticky)
  }
}

init()
