import '../../vendors/swiper/swiper.scss'

import Swiper from 'swiper'
import { Autoplay, Manipulation, Navigation, Pagination } from 'swiper/modules'

import { ADA_DISABLED_EVENT, ADA_ENABLED_EVENT } from '../../accessibility/constants'
import { currentDevice } from '../../core/utils/currentDevice'
import { updateLastSearches } from '../../shared/last_searches/last_searches'
import { LAST_SEARCHES_LOADED_EVENT } from '../../shared/last_searches_cards/last_searches_cards.constants'
import { loadHlsVideo } from '../../utils/load_hls_video'
import { addSourcesToVideosFromDataAttributes } from '../../utils/videoUtils'

import { showCountdown } from './cta_info_card/cta_info_card'
import { initializeUtagEvents } from './home_megabanner_slider.analytics'

const BLOCK_SELECTOR = '.js-home-megabanner-slider'
const CORPORATIVE_SLIDE_SELECTOR = '.js-corporative-banner'
const INFO_CARD_SELECTOR = '.js-home-megabanner-slider-info-card'
const SLIDER_CONTROLS_SELECTOR = '.js-home-megabanner-slider-controls'

/**
 * Gets last visited hotel data from localstorage
 *
 * @returns last visited hotel data
 */
function getLastVisitedHotel() {
  let local_last_searches = localStorage.getItem('last_searches')
  if (!local_last_searches) {
    return false
  }

  local_last_searches = JSON.parse(local_last_searches)
  if (!local_last_searches.length) {
    return false
  }

  // Get only hotel searches and take the last one
  const lastVisitedHotel = local_last_searches.filter(search => search.place_to_go.includes('h')).pop()

  return lastVisitedHotel
}

/**
 * Create a new slide with the last search hotel data
 *
 * @param {Node} megabannerSlider
 */
function createLastVisitedHotelSlide(megabannerSlider, swiper) {
  const lastVisitedHotelSlide = megabannerSlider.querySelector('.js-last-searches-slide')
  if (!lastVisitedHotelSlide) {
    return
  }

  // Update last searches before adding the last visited hotel card to the swiper
  updateLastSearches(() => {
    const lastVisitedHotel = getLastVisitedHotel()
    if (!lastVisitedHotel) {
      lastVisitedHotelSlide.remove()
      const homeMegabanner = document.querySelector(BLOCK_SELECTOR)
      homeMegabanner.classList.add('margin-extra')
    } else {
      const hotelNameContainer = lastVisitedHotelSlide.querySelector('[data-hotel-name]')
      const hotelStarsContainer = lastVisitedHotelSlide.querySelector('[data-stars-name]')
      const hotelDescriptionContainer = lastVisitedHotelSlide.querySelector('[data-hotel-description]')
      const visitButton = lastVisitedHotelSlide.querySelector('[data-hotel-url]')
      const hotelImage = lastVisitedHotelSlide.querySelector('[data-last-search-hotel-img]')

      if (IB.currentDevice === 'desktop') {
        hotelImage.src = lastVisitedHotel.image_desktop
      } else if (IB.currentDevice === 'mobile') {
        hotelImage.src = lastVisitedHotel.image_mobile
      } else {
        hotelImage.src = lastVisitedHotel.image_tablet
      }
      // Add info last search hotel to card
      hotelNameContainer.innerHTML = lastVisitedHotel.title
      hotelNameContainer.setAttribute('data-hotel-name', lastVisitedHotel.title)
      hotelStarsContainer.classList.add(`r${lastVisitedHotel.category}*`)
      hotelDescriptionContainer.innerHTML = lastVisitedHotel.description

      // Add url to button
      const urlDomain = `${window.location.protocol}//${window.location.hostname}`
      const urlVisit = urlDomain + lastVisitedHotel.path
      visitButton.href = urlVisit

      // Reduce width if there is more than one card in mobile
      window.addEventListener(LAST_SEARCHES_LOADED_EVENT, () => {
        if (currentDevice.isMobile) {
          const cards = megabannerSlider.querySelectorAll('.js-last-search-card')
          if (cards.length > 1) {
            cards.forEach(card => {
              card.classList.add('reduce-width')
            })
          }
        }
      })

      lastVisitedHotelSlide.classList.remove('is-hidden')
      swiper.addSlide(swiper.realIndex + 1, lastVisitedHotelSlide)
      swiper.update()
    }
  })
}

function manageSlideVideoPlayingState(swiper, index, state = 'stop') {
  const slide = swiper.slides[index]
  const video = slide.querySelector('video')

  if (!video) return

  if (state === 'play') {
    video.play()
  } else {
    video.pause()
    video.currentTime = 0
  }
}
function showInfoCards(parentElem) {
  const infoCards = parentElem.querySelectorAll(INFO_CARD_SELECTOR)

  if (!infoCards.length) return

  infoCards.forEach(infoCard => {
    infoCard.classList.remove('is-hidden')
  })
}

function init() {
  const megabannerSlider = document.querySelector(BLOCK_SELECTOR)

  if (!megabannerSlider) return

  const autoplaySpeed = (megabannerSlider.dataset.autoplaySpeed || 6) * 1000

  // Remove corporative slide
  const corporativeSlide = megabannerSlider.querySelector(CORPORATIVE_SLIDE_SELECTOR)
  if (corporativeSlide) {
    corporativeSlide.remove()
  }

  initializeUtagEvents()
  // Initialize swiper
  const swiperElement = megabannerSlider.querySelector('.js-swiper-container')

  Swiper.use([Manipulation, Navigation, Pagination, Autoplay])
  new Swiper(swiperElement, {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    speed: 800,
    effect: 'fade',
    autoplay: {
      delay: autoplaySpeed,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.js-swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    navigation: {
      prevEl: megabannerSlider.querySelector('.home-megabanner-slider__button.nav-btn-prev'),
      nextEl: megabannerSlider.querySelector('.home-megabanner-slider__button.nav-btn-next'),
    },
    on: {
      init(swiper) {
        showInfoCards(swiper.el)
        // Create last visited hotel slide
        createLastVisitedHotelSlide(megabannerSlider, swiper)
      },
      slideChange(swiper) {
        manageSlideVideoPlayingState(swiper, swiper.previousIndex, 'stop')
        manageSlideVideoPlayingState(swiper, swiper.activeIndex, 'play')
      },
    },
  })

  // Show controls
  const sliderControls = document.querySelector(SLIDER_CONTROLS_SELECTOR)
  sliderControls?.classList.remove('is-hidden')

  // Load videos
  const videos = megabannerSlider.querySelectorAll('.js-head-video')
  if (videos.length) {
    addSourcesToVideosFromDataAttributes(videos)
  }
  loadHlsVideo(BLOCK_SELECTOR, {
    autoplay: false,
    loop: true,
    playsWhenEnterViewport: false,
  })

  // Each slide could have a wrapping link, that is, the whole slide is clickable and should redirect to wrapping link url
  // but for html semantic reasons is not possible use <a> tag as a wrapper (has buttons and another <a> tags inside)
  // Because of this, an inline hidden link was rendered in the html and we trigger it through slide click.
  const megabannerItemsWithLink = megabannerSlider.querySelectorAll('.js-slide-element-with-link')

  if (megabannerItemsWithLink.length) {
    megabannerItemsWithLink.forEach(item => {
      item.addEventListener('click', event => {
        if (event.target.tagName === 'A' || event.target.tagName === 'BUTTON') return
        item.querySelector('.js-slide-link')?.click()
      })
    })
  }

  // Initialize countdowns
  showCountdown(BLOCK_SELECTOR)
}

// Public
window.IB.megabanner = {
  startSlider: init,
}

// Initialize
;(() => {
  const megabannerSlider = document.querySelector(BLOCK_SELECTOR)

  if (!megabannerSlider) return

  const corporativeSlide = megabannerSlider.querySelector(CORPORATIVE_SLIDE_SELECTOR)

  if (!corporativeSlide || IB.is_in_microsite) {
    init()
  }

  // A11y - changes main header background
  const mainHeaderWrapper = document.querySelector('.wrapper-main-header')
  const noTransitionDuration = 600
  const backgroundHorizonsDelay = 200

  function handleTransition(enable) {
    if (!mainHeaderWrapper) return

    mainHeaderWrapper.classList.add('no-transition')

    setTimeout(() => {
      if (enable) {
        mainHeaderWrapper.classList.add('background-horizons')
        document.body.classList.remove('transparent-header')
      } else {
        mainHeaderWrapper.classList.remove('background-horizons')
        document.body.classList.add('transparent-header')
      }
    }, backgroundHorizonsDelay)

    setTimeout(() => {
      mainHeaderWrapper.classList.remove('no-transition')
    }, noTransitionDuration)
  }

  document.addEventListener(ADA_ENABLED_EVENT, () => handleTransition(true))
  document.addEventListener(ADA_DISABLED_EVENT, () => handleTransition(false))

  // Show the white header if you start loading with ADA on
  document.addEventListener('DOMContentLoaded', () => {
    if (document.body.classList.contains('ada')) {
      handleTransition(true)
    }
  })
})()
