import '../../vendors/template_engine/template_engine'

import { getMobileOperatingSystem } from '../../utils/get_mobile_os'

function buildLightbox($social_share_trigger, $lightbox) {
  const data = getShareData($social_share_trigger)

  $lightbox = $lightbox || $('#shared-block')

  openFancybox($lightbox, data)
  shareTwitter($lightbox, data)
  shareFacebook($lightbox, data)
  shareByEmail($lightbox, data)
}

function openFancybox($lightbox, data) {
  $.fancybox.open($lightbox, {
    autoSize: true,
    wrapCSS: 'shared-block',
    padding: 0,
    beforeShow() {
      const template = $('#fancybox-desc-card-template').html()
      const $desc = $lightbox.find('.desc-cnt')

      // cleans previous card template appended, if any
      $desc.find('.desc-card').remove()

      $desc.append(TemplateEngine(template, data))

      // give it another thought
      // prevents double title (probably being injected from last searches)
      $('.mobile-only.mobile-title.t-highlight.share-page').remove()
    },
    afterClose() {
      $('#shared-mails, .multimails-hidden-input').val('')
      $('.multimails-container').find('[data-email]').remove()
      hideForm() // hides form if opened
      $.fancybox.destroy()
    },
  })
}

//
// social media available shares
//
function shareTwitter($lightbox, data) {
  const twitter_selector = '.js-share-twitter'

  if (!$(twitter_selector).length) return

  if (data.hotelNameShared !== undefined || data.hotelNameShared !== '') {
    $(twitter_selector).attr('data-hotel-shared', data.hotelNameShared)
  }

  clearPreviousClickEvents($lightbox, twitter_selector)
  $(document).on('click', $lightbox.find(twitter_selector).selector, function (e) {
    e.preventDefault()
    window.open(
      `https://twitter.com/intent/tweet?text=${data.description}&url=${data.url}`,
      'ShareOnTwitter',
      getWindowOptions()
    )
  })
}

function shareFacebook($lightbox, data) {
  const facebook_selector = '.js-share-facebook';

  // Verificar si el selector existe antes de proceder
  if (!$(facebook_selector).length) return;

  // Asignar el atributo solo si data.hotelNameShared tiene un valor válido
  if (data.hotelNameShared !== undefined && data.hotelNameShared !== '') {
    $(facebook_selector).attr('data-hotel-shared', data.hotelNameShared);
  }

  // Limpiar eventos de click anteriores
  clearPreviousClickEvents($lightbox, facebook_selector);

  // Asignar el evento click al selector específico
  $(facebook_selector).on('click', function (e) {
    e.preventDefault();

    // Construir la URL de compartir en Facebook correctamente
    let facebookShareUrl = `https://www.facebook.com/sharer.php?`;
    facebookShareUrl += `u=${encodeURIComponent(data.url)}`;

    // Abrir la ventana de compartir en Facebook
    window.open(
      facebookShareUrl,
      'facebookwindow',
      'width=600,height=400'
    );
  });
}


function shareByEmail($lightbox, data) {
  const email_selector = '.share-email'

  if (!$(email_selector).length) return

  if (data.hotelNameShared !== undefined || data.hotelNameShared !== '') {
    $(email_selector).attr('data-hotel-shared', data.hotelNameShared)
  }

  clearPreviousClickEvents($lightbox, '.share-email')

  populateForm($lightbox, data)

  // shows the form
  $(document).on('click', $lightbox.find(email_selector).selector, function (e) {
    e.preventDefault()
    showForm()
  })

  // hides the form
  $(document).on('click', $lightbox.find('.come-back-btn').selector, function (e) {
    e.preventDefault()
    hideForm()
  })
}

//
// visibility helpers
//
function showForm() {
  // TODO: change to css class to manage this
  $('.shared-block .social-share-list').hide()
  $('.shared-block .form-cnt').show()
  $('.shared-block .form-cnt .action .come-back').show()

  const prop = IB.currentDevice === 'desktop' ? 'flex' : 'block'
  $('.shared-block .form-cnt .action').css('display', prop)
}

function hideForm() {
  // TODO: change to css class to manage also this
  $('.shared-block .social-share-list').show()
  $('.shared-block .form-cnt').hide()
  $('.shared-block .form-cnt .action .come-back').hide()
  if (IB.currentDevice === 'mobile') {
    $('.shared-block .social-share-list').hide()
    $('.shared-block .desc-cnt').show()
    $('.shared-block .mobile-only.share-list').show()
  }
}

// fills the form field with the data
function populateForm($lightbox, data) {
  $lightbox.find('#shared-subject-title').val(data.title)
  $lightbox.find('.share-list .whatsapp').attr('href', `whatsapp://send?text=${data.description}\n ${data.url}`)
  const chrt = getMobileOperatingSystem() === 'ios' ? '&' : '?'
  $lightbox.find('.share-list .sms').attr('href', `sms:///${chrt}body=${data.description}\n ${data.url}`)
  $lightbox.find('textarea[name=shared-subject]').text(`${data.description} ${data.url}\n`)
  $lightbox.find('#url-hidden-input').val(data.url)
  $lightbox.find('.copy button').removeClass('selected').attr('data-clipboard-text', data.url)
}

// gets the data-attrs from the fancybox trigger link
function getShareData($social_share_trigger) {
  return $social_share_trigger.data()
}

// remove previously attached click events
// prevents bugs when playing to open/close lightboxes
// from different sources (shared searches, favourite hotels...)
function clearPreviousClickEvents($lightbox, css_selector) {
  $(document).off('click', $lightbox.find(css_selector).selector)
}

// default size for twitter/facebook share window
function getWindowOptions() {
  return 'resizable,scrollbars,status,height=350,width=350'
}

window.IB.socialShareBuilder = {
  buildLightbox,
  shareTwitter,
  shareFacebook,
  shareByEmail,
}
